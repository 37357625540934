import React from "react";
import { Text, LanguageContext } from "../containers/Language";
const moblielotimage = require("../images/Online_Training_Videos.png");

export default function InstallLogin_Hindi() {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={moblielotimage} className="mobilelot-image" />
      </div>
      <div
        style={{
          background: "#DCDCDC",
          display: "flex",
          alignItems: "center",
          padding: "30px",
          width: "100%",
          marginLeft: "0%",
          marginTop: "0.3px",
        }}
      >
        <div>
          <h5 style={{ color: "#00035A" }}>
            {" "}
            <Text tid="install_login_video" />
          </h5>
        </div>
        <div style={{ marginLeft: "40%" }}>
          <div>
            <h5 style={{ color: "#00035A" }}>
              <Text tid="language" /> <Text tid="colon" /> <Text tid="hindi" />
            </h5>
          </div>
          <div>
            <h5 style={{ color: "#00035A" }}>
              <Text tid="duration" /> <Text tid="colon" />{" "}
              <Text tid="install_video_duration" />
            </h5>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          marginTop: "0px",
        }}
      >
        <iframe
          width="1140"
          height="642"
          src="https://www.youtube.com/embed/orUxBS4a0-s"
          title="Mobilelot Install Hindi"
          frameborder="0"
          allow="accelerometer; autoplay; 
        clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}
