import { Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import Home from "../routes/Home";
import GetToken from "../routes/GetToken";
import InstallLogin from "../routes/InstallLogin";
import InstallLogin_English from "../routes/InstallLogin_English";
import InstallLogin_Bangla from "../routes/InstallLogin_Bangla";
import InstallLogin_Marathi from "../routes/InstallLogin_Marathi";
import InstallLogin_Punjabi from "../routes/InstallLogin_Punjabi";
import InstallLogin_Hindi from "../routes/InstallLogin_Hindi";
import LotterySambadCurrent from "../routes/LotterySambadCurrent";
import LotterySambadPrevious from "../routes/LotterySambadPrevious";
import DhankesariCurrent from "../routes/DhankesariCurrent";
import DhankesariPrevious from "../routes/DhankesariPrevious";
import CurrentDraw from '../routes/CurrentDraw';
import CurrentDraw_Punjabi from '../routes/CurrentDraw_Punjabi';
import CurrentDraw_Marathi from '../routes/CurrentDraw_Marathi';
import CurrentDraw_Hindi from '../routes/CurrentDraw_Hindi';
import CurrentDraw_Bengali from '../routes/CurrentDraw_Bengali';
import UnsoldAdvance_Draw from '../routes/UnsoldAdvance_Draw';
import UnsoldAdvanceDate from '../routes/UnsoldAdvanceDate';
import TransactionRemovedUnsold from '../routes/TransactionRemovedUnsold';
import FullSold from '../routes/FullSold';
import Purchasereturndetails from '../routes/PurchaseReturnDetails';
import PurchaseDetails from '../routes/PurchaseDetails';
import PurchaseSummary from '../routes/PurchaseSummary';
import PurchasereturnSummary from '../routes/PurchaseReturnSummary';
import PurchaseandPurchaseReturn from '../routes/PurchaseandPurchaseReturn';
import PWTSold from '../routes/PWTSold';
import PWTUNSold from '../routes/PWTUnSold';
import RemoveUnsold from '../routes/RemoveUnsold';
import TicketSearch from '../routes/TicketSearch';
import StatatemenDetails from '../routes/StatementDetails';
import StatatemenSummary from '../routes/StatementSummary';
import { LanguageProvider } from "../containers/Language";
import LanguageSelector from "./LanguageSelector";
import Footer from "./Footer";

const App = () => {
  return (
    <LanguageProvider>
      <div /* style={{ marginTop: 60, textAlign: "right", marginRight: 50 }} */ className="language_seleactor_menu">
        <LanguageSelector />
      </div>
      <Routes>
          <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<p>Not found!</p>} />
          <Route path="install_login_english" element={<InstallLogin_English/>}/>
          <Route path="install_login_bangala" element={<InstallLogin_Bangla/>}/>
          <Route path="install_login_hindi" element={<InstallLogin_Hindi/>}/>
          <Route path="install_login_punjabi" element={<InstallLogin_Punjabi/>}/>
          <Route path="install_login_marathi" element={<InstallLogin_Marathi/>}/>
          <Route path="get_token" element={<GetToken/>}/>
          <Route path="lottery_sambad_current" element={<LotterySambadCurrent/> }/>
          <Route path="lottery_sambad_previous" element={<LotterySambadPrevious/>}/>
          <Route path="dhankesari_current" element={<DhankesariCurrent/>}/>
          <Route path="dhankesari_previos" element={<DhankesariPrevious/>}/>
          <Route path="current_draw_english" element={<CurrentDraw/>}/>
          <Route path="current_draw_hindi" element={<CurrentDraw_Hindi/>}/>
          <Route path="current_draw_marathi" element={<CurrentDraw_Marathi/>}/>
          <Route path="current_draw_punjabi" element={<CurrentDraw_Punjabi/>}/>
          <Route path="current_draw_bengali" element={<CurrentDraw_Bengali/>}/>
          <Route path="unsoldadvance_draw" element={<UnsoldAdvance_Draw/>}/>
          <Route path="unsoldadvance_date" element={<UnsoldAdvanceDate/>}/>
          <Route path="transactionremovedunsold" element={<TransactionRemovedUnsold/>}/>
          <Route path="full_sold" element={<FullSold/>}/>
          <Route path="purchasereturndetails" element={<Purchasereturndetails/>}/>
          <Route path="purchasedetails" element={<PurchaseDetails/>}/>
          <Route path="purchasereturnSumary" element={<PurchasereturnSummary/>}/>
          <Route path="remove_unsold" element={<RemoveUnsold/>}/>
          <Route path="purchaseandPurchaseReturn" element={<PurchaseandPurchaseReturn/>}/> 
          <Route path="PWT_Sold" element={<PWTSold/>}/>
          <Route path="PWT_UNSold" element={<PWTUNSold/>}/>
          <Route path="ticket_search" element={<TicketSearch/>}/>
          <Route path="statementDetails" element={<StatatemenDetails/>}/>
          <Route path="purchaseSumary" element={<PurchaseSummary/>}/>
          <Route path="statatement_summary" element={<StatatemenSummary/>}/>
          </Route>
      </Routes>  
      <div className="footer_menu_bottom">
      <Footer />
      </div>
      
    </LanguageProvider>
  );
};

export default App;
