import en from './en.json';
import ta from './ta.json';
import hi from './hi.json';
import ba from './ba.json';
import ma from './ma.json';
import pu from './pu.json';
export const dictionaryList = { en,ta,hi,ba,ma,pu };

export const languageOptions = {
  en: 'English',
  ta:  'தமிழ்',
  hi:  'हिंदी',
  ba: 'বাংলা',
  ma: 'मराठी',
  pu: 'ਪੰਜਾਬੀ'
};