import Navbar from './Navbar';
import { Link } from 'react-router-dom';
const logo = require("../images/mobilelotlogo.png");
const Header = () => {
  return (
    <header>
      <div>
        <Link to="/" className="logo">
           <img src={logo} width="120" height="50" className="mobilelot_logo"/>
        </Link>
      </div>
      <Navbar />
    </header>
    
  );
};

export default Header;
