import React from "react";
import {
    Box,
    Container,
    Row,
    Column,
    FooterLink,
    Heading,
  } from "./FooterStyles";
  import { Text, LanguageContext } from '../containers/Language';

function Footer () {
  
return (
	<Box>
   <h5 style={{ color: "white", 
                   textAlign: "center", 
                   marginTop: "10px" }}>
       <Text tid="rights" />
      </h5>
	</Box>
);
};
export default Footer;
