import { menuItems } from '../menuItems';
import MenuItems from './MenuItems';
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
  NavHeader,
} from "./NavbarElements";
const Navbar = () => {
  return (
    <Nav>
      <NavMenu>
      <ul className="menus">
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          return (
            <MenuItems
              items={menu}
              key={index}
              depthLevel={depthLevel}
            >
            </MenuItems>
          );
        })}
         
      </ul>
      
      </NavMenu>
    </Nav>
  );
};

export default Navbar;
