import React, { useState } from "react";
import { useParams } from "react-router";
import HeaderBox from "../components/HeaderBox";
import { Text, LanguageContext } from "../containers/Language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";

const moblielotimage = require("../images/Online_Training_Videos.png");

function Home() {
  const navigate = useNavigate();
  const navigateInstalllogin = () => {
    navigate("install_login_english");
  };
  const navigateTransactions = () => {
    navigate("current_draw_english");
  };
  const naviagateReports = () => {
    navigate("purchasedetails");
  };
  const navigateResults = () => {
    navigate("lottery_sambad_current");
  };
  const naviagateGetToken = () => {
    navigate("get_token");
  };

  return (
    <div>
      <div style={{ justifyContent: "center" }}>
        <img src={moblielotimage} className="mobilelot-image" />
      </div>
      <div className="mobile-lot-home-menu">
      <div>
        <div className="home_page_card_heading">
          <div
            className="tittle_box">
            <HeaderBox
              onClick={navigateInstalllogin}
              style={{
                borderRadius: 5,
                color: "#5C5454",
                minHeight: 100,
                padding: 12,
                width: 200,
                fontWeight: "bold",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                fontSize: 14
              }}
            >
              <FontAwesomeIcon icon={faCloudArrowDown} fontSize={35} color="#001881" />
              <h5 style={{ marginTop: 15 ,color:'#001881'}}>
                <Text tid="install_login"  />
              </h5>
            </HeaderBox>

            <HeaderBox
              onClick={navigateTransactions}
              style={{
                borderRadius: 5,
                color: "#5C5454",
                minHeight: 100,
                padding: 12,
                width: 200,
                fontWeight: "bold",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                fontSize: 14
              }}
            >
              <FontAwesomeIcon icon={faDesktop} fontSize={35}  color="#001881"/>
              <h5 style={{ marginTop: 15,color:'#001881' }}>
                <Text tid="transactions" />
              </h5>
            </HeaderBox>
            <HeaderBox
              onClick={naviagateReports}
            
              style={{
                
                borderRadius: 5,
                color: "#5C5454",
                minHeight: 100,
                padding: 12,
                width: 200,
                fontWeight: "bold",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                fontSize: 14
              }}
            >
              <FontAwesomeIcon icon={faChartLine} fontSize={35} color="#001881" />
              <h5 style={{ marginTop: 15,color:'#001881' }}>
                <Text tid="reports" />
              </h5>
            </HeaderBox>
          </div>
        </div>

        <div style={{ marginLeft: "5%", marginRight: "5%", marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <HeaderBox
              onClick={navigateResults}
              style={{
                
                borderRadius: 5,
                color: "#5C5454",
                minHeight: 100,
                padding: 12,
                width: 150,
                fontWeight: "bold",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                fontSize: 14,
              }}
            >
              <FontAwesomeIcon icon={faFileInvoiceDollar} fontSize={35} color="#001881"/>
              <h5 style={{ marginTop: 15,color:'#001881' }}>
                {" "}
                <Text tid="results" />
              </h5>
            </HeaderBox>
            <HeaderBox
              onClick={naviagateGetToken}
              style={{
                borderRadius: 5,
                color: "#5C5454",
                minHeight: 100,
                padding: 12,
                width: 200,
                fontWeight: "bold",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                fontSize: 14
              }}
            >
              <FontAwesomeIcon icon={faNewspaper} fontSize={35} color="#001881" />
              <h5 style={{ marginTop: 15,color:'#001881' }}>
                <Text tid="get_token"  />
              </h5>
            </HeaderBox>
            <HeaderBox
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: 5,
                color: "#5C5454",
                minHeight: 100,
                padding: 12,
                width: 200,
                fontWeight: "bold",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            ></HeaderBox>
          </div>
        </div>
      </div>
      </div>
     
    </div>
  );
}

export default Home;
