
import { Text, LanguageContext } from '../src/containers/Language';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";

export const menuItems = [
  {
    title: <Text tid="install_login" />,
    url: '/install_login_english',
    image: <FontAwesomeIcon icon={faCloudArrowDown} fontSize={20} style={{paddingLeft:'5px',paddingRight:'15px'}} />,
     /* submenu:[
      {
        title: <Text tid="english"/>,
        url: 'install_login_english',
      },
      {
        title: <Text tid="bengali"/>,
        url: 'install_login_bangala',
      },
      {
        title: <Text tid="hindi"/>,
        url: 'install_login_hindi',
      },
      {
        title: <Text tid="marathi"/>,
        url: 'install_login_marathi',
      },
      {
        title: <Text tid="punjabi"/>,
        url: 'install_login_punjabi',
      },
    ]  */
  },
  {
    title:  <Text tid="transactions" />,
    url: '/',
    image: <FontAwesomeIcon icon={faDesktop} fontSize={20} style={{paddingLeft:'5px',paddingRight:'15px'}}/>,
    submenu: [
      {
        title: <Text tid="current_draw"/>,
        url: 'current_draw_english',
       /*  submenu: [
          {
            title: <Text tid="english"/>,
            url: 'current_draw_english',
          },
          {
            title: <Text tid="bengali"/>,
            url: 'current_draw_bengali',
          },
          {
            title: <Text tid="hindi"/>,
            url: 'current_draw_hindi',
          },
          {
            title: <Text tid="marathi"/>,
            url: 'current_draw_marathi',
          },
          {
            title: <Text tid="punjabi"/>,
            url: 'current_draw_punjabi',
          },
        ], */
      },
      {
        title: <Text tid="unsold_advance_draw"/>,
        url: 'unsoldadvance_draw',
      },
      {
        title: <Text tid="unsold_advance_date"/>,
        url: 'unsoldadvance_date',
      },
      {
        title: <Text tid="full_sold"/>,
        url: 'full_sold',
      },
      {
        title: <Text tid="transaction_remove_unsold"/>,
        url: 'transactionremovedunsold',
      },
    ],
  },
  {
    title: <Text tid="reports"/>,
    url: '/',
    image: <FontAwesomeIcon icon={faChartLine} fontSize={20} style={{paddingLeft:'5px',paddingRight:'15px'}} />,
    submenu: [
      {
        title: <Text tid="purchase_details"/>,
        url: 'purchasedetails',
      },
      {
        title: <Text tid="purchase_summary"/>,
        url: 'purchaseSumary',
      },
      {
        title: <Text tid="purchase_return_details"/>,
        url: 'purchasereturndetails',
      },
      {
        title: <Text tid="purchase_return_summary"/>,
        url: 'purchasereturnSumary',
      },
      {
        title: <Text tid="purchase_and_purchase_return"/>,
        url: 'purchaseandPurchaseReturn',
      },
      {
        title: <Text tid="pwt_sold"/>,
        url: 'PWT_Sold',
      },
      {
        title: <Text tid="pwt_unsold"/>,
        url: 'PWT_UNSold',
      },
      {
        title: <Text tid="remove_unsold"/>,
        url: 'remove_unsold',
      },
      {
        title: <Text tid="ticket_search"/>,
        url: 'ticket_search',
      },
      {
        title: <Text tid="summary_details"/>,
        url: 'statementDetails',
      },
      {
        title: <Text tid="statement_summary"/>,
        url: 'statatement_summary',
      },

    ],
  },
  {
    title: <Text tid="results"/>,
    url: '/',
    image: <FontAwesomeIcon icon={faFileInvoiceDollar} fontSize={20} style={{paddingLeft:'5px',paddingRight:'15px'}} />,
    submenu: [
      {
        title: <Text tid="results_lotterysambad_current"/>,
        url: 'lottery_sambad_current',
      },
      {
        title:  <Text tid="results_lotterysambad_previous"/>,
        url: 'lottery_sambad_previous',
      },
      {
        title:  <Text tid="results_dhankesari_current"/>,
        url: 'dhankesari_current',
      },
      {
        title:  <Text tid="results_dhankesari_previous"/>,
        url: 'dhankesari_previos',
      },
    ],
  },
  {
    title:  <Text tid="get_token" style={{textAlign:'right'}}/>,
    url: '/get_token',
    image: <FontAwesomeIcon icon={faNewspaper} fontSize={20} style={{paddingLeft:'5px',paddingRight:'15px'}} />,
    /* submenu:[
      
    ] */
  }
];
